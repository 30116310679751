.logo-desc {
  flex-grow: 1;
  padding-left: 10px;

  & > div:first-child {
    text-transform: uppercase;
  }
}

.main-navigation {
  padding-top: 70px;
}

.progress {
  margin-left: 40px;
  position: absolute;
}
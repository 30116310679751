.google-button {
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  height: 54px;
}

.forgot-link {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding-right: 20px;
}

.facebook-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b5998;
  color: white;
  font-size: 18px;
  font-family: inherit;
  width: 100%;
  margin-bottom: 14px;
  height: 54px;
  cursor: pointer;
  border: none;

  &_icon {
    margin-right: 10px;
  }
}
.paper {
  min-height: 200px;
  padding: 24px 30px;
}

.paperTotal {
  min-height: 200px;
  padding: 24px 10px;
}

.lastList {
  margin-bottom: 40px;
}

.totalPayments {
  margin-bottom: 30px;
}
.google-button {
  width: 100%;
  margin-bottom: 10px;
  height: 54px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;

  cursor: pointer;
  & > div {
    margin-left: 0!important;
  }

  &[disabled] {
    opacity: .6;
  }
}

.google-icon {
  padding: 10px 14px;
  font-size: 18px;
}

.forgot-link {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding-right: 20px;
}

.facebook-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b5998;
  color: white;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  margin-bottom: 14px;
  height: 54px;
  cursor: pointer;
  border: none;
  padding: 0 10px;

  &_icon {
    margin-right: 10px;
    font-size: 10px;
    margin-left: 2px;
  }
}
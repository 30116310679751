.main {
  padding-top: 40px;
}
.logo {
  text-align: center;
  color: #fff;
  font-family: 'Gilroy';
  font-size: 22px;
  margin-bottom: 18px;

  &_desc {
    font-size: 16px;
  }
}
.message {
  text-align: center;
  color: #fff;
}
.paper {
  min-height: 200px;
  padding: 24px 30px;
}

.paperTotal {
  min-height: 200px;
  padding: 24px 10px;
}

.lastList {
  margin-bottom: 40px;
}

.totalPayments {
  margin-bottom: 30px;
}

.payoutDesc {
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  opacity: .8;
}

.payoutCardDesc {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  opacity: .8;
}

.error {
  font-size: 12px;
  color: #f44336;
  padding-top: 10px;
  height: 30px;
}
.preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #D8252B;
    background-color: #eeeeee;
    /* will-change: opacity; */
    transition: 0.6s;
    z-index: 100001;
    overflow: hidden;
    overscroll-behavior: none;

    &_is-hidden {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scaleY(3);
    }
    100% {
        transform: scale(1);
    }
}

.logo {
    font-size: 40px;
}

.app-logo {
    fill: currentColor;
}

.progress-wrapper {
    display: flex;
    margin-right: 2px;
    height: 20px;
    align-items: flex-end;

    & > * {
        margin-left: 2px;
        margin-top: 20px;
    }
}

.progress-point {
    height: 4px;
    width: 10px;
    background-color: #D8252B;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    transform-origin: center center;

    &:first-of-type {
        opacity: 0.8;
        width: 8px;
    }

    &:last-of-type {
        opacity: 0.8;
        width: 8px;
    }
}
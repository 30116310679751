.main {
  margin-bottom: 40px;
  width: 200px;
  text-align: center;
}

.qrCode {
  margin-bottom: 10px;
}

.button {
  display: inline-block;
}
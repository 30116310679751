.color {
  &-green {
    color: #09ab3f
  }
  &-red {
    color: -red
  }
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.desc {
  text-transform: uppercase;
}
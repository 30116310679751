.main {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.body {
  display: flex;
  flex-direction: column;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.color {
  &-green {
    color: #09ab3f
  }
  &-red {
    color: -red
  }
}

.title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.value {
  font-size: 24px;
  margin-bottom: 10px;
}

.desc {
}